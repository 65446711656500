/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W 2021 roku firma Transpol Lider realizowała inwestycję pn. „Budowa ronda na skrzyżowaniu drogi krajowej nr 62 z drogą wojewódzką nr 412 i drogą gminną wraz z dojazdami i infrastrukturą techniczną stanowiącą całość funkcjonalno – użytkową w Kobylnikach”."), "\n", React.createElement(_components.p, null, "Dotychczas istniejące skrzyżowanie w Kobylnikach nie było wystarczające dla rosnącego w tym miejscu natężenia ruchu drogowego. Geometria dróg dojazdowych, czyli układ łączący drogę krajową z wojewódzką i gminną, a także bliskość zakładów przemysłowych sprawiły, że skrzyżowanie to wymagało kompleksowej rozbudowy."), "\n", React.createElement(_components.p, null, "W ramach zadania realizowane zostały:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "czterowlotowe rondo,"), "\n", React.createElement(_components.li, null, "chodniki,"), "\n", React.createElement(_components.li, null, "zatoki autobusowe"), "\n", React.createElement(_components.li, null, "oświetlenie drogowe."), "\n", React.createElement(_components.li, null, "poszerzenie jezdni do 3,5 metra"), "\n", React.createElement(_components.li, null, "dostosowanie nawierzchni do nośności 11,5 t/oś."), "\n"), "\n", React.createElement(_components.p, null, "Realizacja zadania była ważna zarówno dla kierowców i pieszych, jak i dla rozwoju gospodarczego okolicznych miejscowości. Rondo bowiem poprawi dostępność terenów przemysłowych na północnym krańcu Kruszwicy."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
